import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

function PartnersTwo() {
  const matches = useMediaQuery("(max-width:650px)");
  const [hueRotate, setHueRotate] = useState(0);
  const animateRef = useRef();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const animate = useCallback(() => {
    setHueRotate((prevHueRotate) => (prevHueRotate + 1) % 360);
    animateRef.current = requestAnimationFrame(animate);
  });
  useEffect(() => {
    animateRef.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(animateRef.current);
    };
  }, [animate]);
  return (
    <section id="specials">
      <div className="weiterempfehlung overflow-hidden">
        <div className="section-2 wf-section">
          <div className="container-lg content-centered">
            Some of our partners who already trust us and for whom we will
            advertise
          </div>
          <div className="slider-wrapper">
            <div className="items-holder">
              <div className="items">
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/acer.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/bose.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/brother.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/dell.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/booking_com.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/fedex.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/mcafee.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/nordvpn.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/nike.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/adidas.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/swiss.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/emirates.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/lg.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/bo.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/lacoste.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/avg.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/norton.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/expedia.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/momondo.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/thenorthface2.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
              </div>
              <div className="items">
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/acer.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/bose.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/brother.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/dell.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/booking_com.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/fedex.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/mcafee.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/nordvpn.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/nike.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/adidas.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/swiss.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/emirates.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/lg.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/bo.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/lacoste.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/avg.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/norton.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/expedia.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/momondo.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
                <a className="item2" href="#">
                  <img
                    style={{ maxHeight: 120, maxWidth: 100 }}
                    src="images/partner_logos/thenorthface2.png"
                    loading="eager"
                    alt=""
                    className="item"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="cf-pricing-hero-section">
          <div className="cf-wrapper-1200px">
            <div className="cf-pricing-hero-wrapper">
              <div className="margin-bottom margin-xl">
                <div className="w-layout-grid caption-holder-center">
                  <div
                    id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa81b-052a211e"
                    className="line-holder invert"
                  >
                    <div
                      id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa81c-052a211e"
                      className="line-animated"
                    />
                  </div>
                  <div className="w-layout-grid row-sm">
                    <div className="caption opacity-50">03</div>
                    <div className="caption">Bonus specials</div>
                  </div>
                  <div
                    id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa822-052a211e"
                    className="line-holder"
                  >
                    <div
                      id="w-node-c6392b0c-06f1-67c6-8e3e-43b182ffa823-052a211e"
                      className="line-animated"
                    />
                  </div>
                </div>
              </div>
              <div className="cf-pricing-hero-head-container">
                <h1 className="cf-pricing-hero-heading">
                  One, Two, Three Bonuses:
                  <br />
                  Just Fulfill the{" "}
                  <span className="cf-colourful-text">Criteria!</span>
                </h1>
                {/* <p class="cf-pricing-hero-para">underline</p> */}
              </div>
            </div>
          </div>
          <div className="cf-pricing-box-section">
            <div className="cf-wrapper-1200px">
              <div className="cf-pricing-box-wrapper pricing-1-box">
                <div className="cf-pricing-box-row pricing-box-1">
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="cf-pricing-box pricing-1-box"
                      style={{
                        width: matches ? "100%" : "95%",
                        height: "100%",
                      }}
                    >
                      <div className="cf-pricing-box-plan-wrapper">
                        <div className="cf-pricing-plan-text pricing-1-plan-text">
                          Referral Bonus{" "}
                          <img
                            src="images/flame-icon-png-8.jpg"
                            style={{ marginBottom: 4 }}
                            height="20px"
                            width="20px"
                          />
                        </div>
                        <div className="text-block-15">
                          Use your personal referral code and get your bonus!
                        </div>
                        <p />
                        <div className="cf-pricing-plan-price">
                          get +5%
                          <span className="cf-pricing-plan-small-text">
                            extra
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper">
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">5% bonus for you</div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            5% bonus for your friend
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            personal referral Code
                          </div>
                        </div>
                      </div>
                      <a
                        href="#presale"
                        className="cf-secondary-button pricing-button w-button"
                      >
                        Buy now
                      </a>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="cf-pricing-box pricing-1-box"
                      style={{
                        width: matches ? "100%" : "95%",
                        height: "100%",
                      }}
                    >
                      <div className="cf-pricing-box-plan-wrapper white-border">
                        <div className="cf-pricing-plan-text">
                          Reward Booster{" "}
                          <img
                            src="images/flame-icon-png-8.jpg"
                            style={{ marginBottom: 4 }}
                            height="20px"
                            width="20px"
                          />
                        </div>
                        <div className="text-block-15">
                          Buy more and get more SURF token rewards as an
                          extension user
                        </div>
                        <p />
                        <div className="cf-pricing-plan-price">
                          up to 250%
                          <span className="cf-pricing-plan-small-text">
                            /rewards
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper">
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            10% more rewards from $100
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            50% more rewards from $500
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            100% more rewards from $1000
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            250% more rewards from $2000
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            For PreSale customers only!
                          </div>
                        </div>
                      </div>
                      <a
                        href="#presale"
                        className="cf-secondary-button pricing-button w-button"
                      >
                        Buy now
                      </a>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-sine"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="cf-pricing-box pricing-1-box"
                      style={{
                        width: matches ? "100%" : "95%",
                        height: "100%",
                      }}
                    >
                      <div className="cf-pricing-box-plan-wrapper">
                        <div className="cf-pricing-plan-text">
                          Staking Bonus{" "}
                          <img
                            src="images/flame-icon-png-8.jpg"
                            style={{ marginBottom: 4 }}
                            height="20px"
                            width="20px"
                          />
                        </div>
                        <div className="text-block-15">
                                                  Stake now without lock time!{" "}
                        </div>
                        <p />
                        <div className="cf-pricing-plan-price">
                          up to 5000%
                          <span className="cf-pricing-plan-small-text">
                            /APY
                          </span>
                        </div>
                      </div>
                      <div className="cf-pricing-plan-content-wrapper">
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            Buy, claim and stake
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            get up to 5000% APY
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            No lock time
                          </div>
                        </div>
                        <div className="cf-pricing-plan-content-item">
                          <img
                            src="images/647bdd22ca507e0459f7b511_2.svg"
                            loading="lazy"
                            alt=""
                            className="cf-pricing-plan-tick-icon"
                          />
                          <div className="text-block-16">
                            APY decreases with each user. So: 
Stake now and secure the a high APY! Your start APY will stay. No reduction. Start now and get the highest profit. Just earn your first tokens during the PreSale!
                          </div>
                        </div>
                      </div>
                      <a
                        href="#presale"
                        className="cf-secondary-button pricing-button w-button"
                      >
                        Buy now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-centered text-lg _52ch">
          <p></p>
          <center>
            Take a look at our pricing structure and take advantage of this
            unique opportunity to join us from the beginning and enjoy the
            benefits.
          </center>
          <p />
        </div>
        <div className="wrapper-parent">
          <div className="container-xl">
            <div className="w-layout-grid sale-stages">
              <div className="item-sale rounded-xs abgedunkelt">
                <div className="header-sale-copy">
                  <div className="margin-b-md">
                    <div className="active">FINISHED</div>
                    <div className="w-layout-grid column-sm content-centered">
                      <div>
                        <h5 className="font-title">Private PreSale</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sale-stage-div">
                  <div className="margin-b-lg">
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90937-052a211e"
                        className="text-block-15"
                      >
                        Token price:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90939-052a211e"
                        className="text-block-16"
                      >
                        $0.01 per 1 SURF
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c9093c-052a211e"
                        className="text-block-15"
                      >
                        Increase price:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c9093e-052a211e"
                        className="text-block-16"
                      >
                        + $0.001 per 1 SURF
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90941-052a211e"
                        className="text-block-15"
                      >
                        Increase duration:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90943-052a211e"
                        className="text-block-16"
                      >
                        14 days
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90946-052a211e"
                        className="text-block-15"
                      >
                        Availability:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90948-052a211e"
                        className="text-block-16"
                      >
                        Whitelist
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-w-id="771fcbad-a366-794a-2d40-e3720def9620"
                style={{
                  filter: `saturate(100%) hue-rotate(${hueRotate}deg)`,
                }}
                className="item-sale rounded hervorgehoben"
              >
                <div className="header-sale-copy">
                  <div className="margin-b-md">
                    <div className="active">ACTIVE</div>
                    <div className="w-layout-grid column-sm content-centered active-sale">
                      <div>
                        <h5 className="font-title">Public PreSale</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sale-stage-div">
                  <div className="margin-b-lg">
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90937-052a211e"
                        className="text-block-15"
                      >
                        Token price:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90939-052a211e"
                        className="text-block-16"
                      >
                        $0.02 per 1 SURF
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c9093c-052a211e"
                        className="text-block-15"
                      >
                        Increase price:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c9093e-052a211e"
                        className="text-block-16"
                      >
                        + $0.00025 per 1 SURF
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90941-052a211e"
                        className="text-block-15"
                      >
                        Increase duration:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90943-052a211e"
                        className="text-block-16"
                      >
                        7 days
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90946-052a211e"
                        className="text-block-15"
                      >
                        Minimum Invest:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c90948-052a211e"
                        className="text-block-16"
                      >
                        ./.
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c9094b-052a211e"
                        className="text-block-15"
                      >
                        Maximum Invest:
                      </div>
                      <div
                        id="w-node-_764f7307-e367-6f33-547d-394826c9094d-052a211e"
                        className="text-block-16"
                      >
                        ./.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-sale rounded abgedunkelt">
                <div className="header-sale-copy">
                  <div className="margin-b-md">
                    <div className="w-layout-grid column-sm content-centered">
                      <div>
                        <h5 className="font-title">Listing price</h5>
                      </div>
                      <div className="w-layout-grid row-xs items-center"></div>
                    </div>
                  </div>
                </div>
                <div className="sale-stage-div">
                  <div className="margin-b-lg">
                    <div className="div-block-3">
                      <div
                        id="w-node-d7afdb0c-278e-a91c-204b-09eee2589f3f-052a211e"
                        className="text-block-15"
                      >
                        Exchange type:
                      </div>
                      <div
                        id="w-node-d7afdb0c-278e-a91c-204b-09eee2589f41-052a211e"
                        className="text-block-16"
                      >
                        DEX + CEX
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-d7afdb0c-278e-a91c-204b-09eee2589f49-052a211e"
                        className="text-block-15"
                      >
                        Token price:
                      </div>
                      <div
                        id="w-node-d7afdb0c-278e-a91c-204b-09eee2589f4b-052a211e"
                        className="text-block-16"
                      >
                        $0.05 per 1 SURF
                      </div>
                    </div>
                    <div className="div-block-3">
                      <div
                        id="w-node-d7afdb0c-278e-a91c-204b-09eee2589f49-052a211e"
                        className="text-block-15"
                      >
                        Initial Market Cap:
                      </div>
                      <div
                        id="w-node-d7afdb0c-278e-a91c-204b-09eee2589f4b-052a211e"
                        className="text-block-16"
                      >
                        $10,000,000
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper-parent">
          <div className="container-xl fade-in-on-scroll">
            <div className="w-layout-grid card rounded-xs">
              <div className="image-wrapper-16-9 bg-indigo-500">
                <img
                  src="images/tokenpie3.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 96vw"
                  // srcSet="images/tokenpie3.png 500w, images/tokenpie3.png 720w"
                  alt=""
                  className="image-bg-cover"
                />
              </div>
              <div className="padding-5xl bg-neutral-800">
                <div className="bg-pattern-wrap centered absolute">
                  <img
                    src="images/bg-shape-02.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, 96vw"
                    // srcSet="images/bg-shape-02-p-500.png 500w, images/bg-shape-02-p-800.png 800w, images/bg-shape-02-p-1080.png 1080w, images/bg-shape-02-p-1600.png 1600w, images/bg-shape-02-p-2000.png 2000w, images/bg-shape-02-p-2600.png 2600w, images/bg-shape-02.png 3200w"
                    alt=""
                    className="bg-pattern-image cover"
                  />
                </div>
                <div>
                  <div className="margin-bottom margin-xl">
                    <div className="w-layout-grid caption-holder">
                      <div className="w-layout-grid row-sm">
                        <div className="caption opacity-50">04</div>
                        <div className="caption">· Surf Token</div>
                      </div>
                      <div
                        id="w-node-dc7e7b5d-9688-601f-cc55-eab907ccf283-052a211e"
                        className="line-holder"
                      >
                        <div
                          id="w-node-dc7e7b5d-9688-601f-cc55-eab907ccf284-052a211e"
                          className="line-animated"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-grid row-md">
                    <div
                      id="w-node-dc7e7b5d-9688-601f-cc55-eab907ccf286-052a211e"
                      className="section-icon"
                    >
                      query_stats
                    </div>
                    <div id="w-node-dc7e7b5d-9688-601f-cc55-eab907ccf288-052a211e">
                      <div className="margin-bottom margin-base">
                        <h2 className="title-sm">
                          Our{" "}
                          <span className="text-gradient__purple">
                            Economics
                          </span>
                          <br />
                        </h2>
                      </div>
                    </div>
                    <div className="text-lg _48ch">
                      PreSale 20%
                      <br />
                      Staking 8%
                      <br />
                      Project Rewards 39%
                      <br />
                      Liquidity &amp; Marketing 25%
                      <br />
                      Development Reserve 6%
                      <br />
                      Partner &amp; Advisor 2%
                      <br />
                      <font color="red">0% Fees!!</font>
                    </div>
                  </div>
                </div>
                <div className="margin-top margin-4xl">
                  <div className="w-layout-grid stats-2x">
                    <div
                      id="w-node-dc7e7b5d-9688-601f-cc55-eab907ccf299-052a211e"
                      className="w-layout-grid column-xs"
                    >
                      <h3
                        id="w-node-dc7e7b5d-9688-601f-cc55-eab907ccf29a-052a211e"
                        className="title-xs"
                      >
                        Contract address:
                      </h3>
                      <div
                        id="w-node-_2c9ce247-4743-4542-309a-dceb2b4cffc6-052a211e"
                        className="_10pad"
                      >
                        <div className="div-block-5">
                          <div
                            className="c-code"
                            title="0x53f1e15ed3Cea8c1d4Adc4BE2DDE4BA33715a922"
                          >
                            0x53f1e15ed3Cea8c1d4Adc4BE2DDE4BA33715a922
                          </div>
                        </div>

                        <a
                          href="#"
                          className="c-copy_button cc-copy w-inline-block"
                        >
                          <div className="c-copy_button-img">
                            <img
                              src="images/copy-icon.svg"
                              loading="lazy"
                              alt=""
                              className="c-icon cc-copy"
                            />
                            <img
                              src="images/copied.svg"
                              loading="lazy"
                              alt=""
                              className="c-icon cc-copied"
                            />
                          </div>
                          <CopyToClipboard
                            text={"0x53f1e15ed3Cea8c1d4Adc4BE2DDE4BA33715a922"}

                          >
                            <div className="c-copy_button-text">
                              <div className="c-text-1 cc-copy">Copy</div>
                              <div className="c-text-1 cc-copied">Copied!</div>
                            </div>
                          </CopyToClipboard>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper-parent hidden">
          <div className="container-lg v">
            <div className="margin-bottom margin-2xl">
              <h4 className="font-title">Token Details:</h4>
            </div>
            <div className="w-layout-grid _3x-feature">
              <div
                id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dbd-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dbe-052a211e"
                  className="font-title"
                >
                  Symbol
                </h6>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc0-052a211e"
                  className="text-sm"
                >
                  SURF
                </div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc2-052a211e"
                  className="_3x-feature-line-bottom"
                />
              </div>
              <div
                id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc3-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc4-052a211e"
                  className="font-title"
                >
                  Name
                </h6>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc6-052a211e"
                  className="text-sm"
                >
                  SURF Reward
                </div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc8-052a211e"
                  className="_3x-feature-line-bottom"
                />
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dc9-052a211e"
                  className="_3x-feature-line-left"
                />
              </div>
              <div
                id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dca-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dcb-052a211e"
                  className="font-title"
                >
                  Platform
                </h6>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dcd-052a211e"
                  className="text-sm"
                >
                  BEP-20 / Binance Smart Chain (BSC)
                </div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dcf-052a211e"
                  className="_3x-feature-line-bottom"
                />
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd0-052a211e"
                  className="_3x-feature-line-left hide-mobile"
                />
              </div>
              <div
                id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd1-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd2-052a211e"
                  className="font-title"
                >
                  Total Supply
                </h6>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd4-052a211e"
                  className="text-sm"
                >
                  1,000,000,000
                </div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd6-052a211e"
                  className="_3x-feature-line-bottom tablet"
                ></div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd7-052a211e"
                  className="_3x-feature-line-left tablet"
                ></div>
                <div
                  id="w-node-_8b948cc0-4548-5153-5926-9b202c914050-052a211e"
                  className="_3x-feature-line-bottom"
                />
                <div
                  id="w-node-_96c2151b-fdd3-6b9a-66a2-a95f5d4345a1-052a211e"
                  className="_3x-feature-line-left hide-mobile"
                />
              </div>
              <div
                id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd8-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dd9-052a211e"
                  className="font-title"
                >
                  Token in PreSale
                </h6>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86ddb-052a211e"
                  className="text-sm"
                >
                  200,000,000
                </div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86dde-052a211e"
                  className="_3x-feature-line-bottom mobile"
                ></div>
              </div>
              <div
                id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86ddf-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86de0-052a211e"
                  className="font-title"
                >
                  Softcap
                </h6>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86de2-052a211e"
                  className="text-sm"
                >
                  $1,500,000
                </div>
                <div
                  id="w-node-fbd15cfa-a38a-5fab-1690-b5bea8f86de4-052a211e"
                  className="_3x-feature-line-left"
                />
                <div
                  id="w-node-eef36834-6623-bb52-8cf7-975d6bb03a02-052a211e"
                  className="_3x-feature-line-bottom mobile"
                ></div>
              </div>
              <div
                id="w-node-_7998875d-be20-a6fa-0ed8-ecec0d12d016-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-_7998875d-be20-a6fa-0ed8-ecec0d12d017-052a211e"
                  className="font-title"
                >
                  Hardcap
                </h6>
                <div
                  id="w-node-_7998875d-be20-a6fa-0ed8-ecec0d12d019-052a211e"
                  className="text-sm"
                >
                  $5,000,000
                </div>
                <div
                  id="w-node-_7998875d-be20-a6fa-0ed8-ecec0d12d01b-052a211e"
                  className="_3x-feature-line-left"
                />
                <div
                  id="w-node-_43406d41-1b1c-93fc-6710-0333399f1e62-052a211e"
                  className="_3x-feature-line-bottom mobile _2"
                />
              </div>
              <div
                id="w-node-_978ade1a-e081-3d8b-bd84-0d78314cb8e8-052a211e"
                className="w-layout-grid column-sm"
              >
                <h6
                  id="w-node-_978ade1a-e081-3d8b-bd84-0d78314cb8e9-052a211e"
                  className="font-title"
                >
                  Listing price
                </h6>
                <div
                  id="w-node-_978ade1a-e081-3d8b-bd84-0d78314cb8eb-052a211e"
                  className="text-sm"
                >
                  $0.05
                </div>
                <div
                  id="w-node-_978ade1a-e081-3d8b-bd84-0d78314cb8ed-052a211e"
                  className="_3x-feature-line-left"
                />
                <div
                  id="w-node-_44ad04bd-f344-1587-fc89-33ee227058cb-052a211e"
                  className="_3x-feature-line-bottom mobile _2 _3"
                />
              </div>
            </div>
          </div>
          <div className="div-block-6">
            <img
              src="images/world-map.png"
              loading="lazy"
              width={1006}
              sizes="(max-width: 479px) 100vw, (max-width: 991px) 90vw, 72vw"
              // srcSet="images/world-map-p-500.png 500w, images/world-map-p-800.png 800w, images/world-map-p-1080.png 1080w, images/world-map.png 1600w"
              alt=""
              className="worldmap"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnersTwo;
