import bnbPresaleAbi from "./bnbPresaleAbi.json";
import ethPresaleAbi from "./ethPreSaleAbi.json";
import stakingAbi from "./stakingAbi.json";
import {
  bscPresaleAddress,
  ethPresaleAddress,
  stakingAddress,
} from "./environment";

import { readContract, waitForTransaction, writeContract } from "wagmi/actions";

export const bnbPresaleMultiReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: bscPresaleAddress,
    abi: bnbPresaleAbi,
    chainId: 56,
    functionName,
    args,
  });
  return data;
};

export const stakingMultiReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: stakingAddress,
    abi: stakingAbi,
    chainId: 56,
    functionName,
    args,
  });
  return data;
};

export const ethPresaleMultiReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: ethPresaleAddress,
    abi: ethPresaleAbi,
    chainId: 1,
    functionName,
    args,
  });
  return data;
};

/// write

export const bnbPresaleMultiWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: bscPresaleAddress,
    abi: bnbPresaleAbi,
    chainId: 56,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const ethPresaleMultiWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: ethPresaleAddress,
    abi: ethPresaleAbi,
    chainId: 1,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
