import { Box, Container, Grid } from "@mui/material";
import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import Loading from "../../components/SmallComponents/loading";
import { ToastNotify } from "../../components/SmallComponents/AppComponents";
function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const matches = useMediaQuery("(max-width:750px)");
  const [loading, setloading] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  ///Add Token To Metamask
  const addTokenToWallet = async () => {
    try {
      const wasAdded = await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x53f1e15ed3Cea8c1d4Adc4BE2DDE4BA33715a922",
            symbol: "SURF",
            decimals: 18,
            image:
              "https://photos.pinksale.finance/file/pinksale-logo-upload/1694053179808-e5c2e11ebd34bfad91451c5d618f39eb.png",
          },
        },
      });

      if (wasAdded) {
        setAlertState({
          open: true,
          message: `Success! Token Added.`,
          severity: "success",
        });
      } else {
        setAlertState({
          open: true,
          message: "Error! Somthing Went Wrong",
          severity: "error",
        });
      }
    } catch (error) {
      console.log(error, "Error to add token");
    }
  };
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Box id="faq" pb={10}>
        <Container maxWidth="lg">
          <Box>
            <Box
              style={{
                fontSize: matches ? "2em" : "4em",
                fontWeight: 600
                , backgroundImage:
                  "linear-gradient(191deg, #fde847 25%, #d97706 87%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "center"
              }}
            >
              Frequently Ask Question
            </Box>
            <Grid container spacing={4} mt={1}>
              <Grid item xs={12} sm={6}>
                <Box borderRadius="5px">
                  <Accordion
                    expanded={expanded === `{panel1}`}
                    onChange={handleChange(`{panel1}`)}
                    style={{
                      borderRadius: "15px",
                      background: "#151b22",
                      width: "100%",
                      m: "auto",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `{panel1}` ? (
                          <Remove
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        ) : (
                          <Add
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        )
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "15px" : "20px"}
                        fontWeight={600}
                        px={2}
                        py={1}
                        color="#ffffff"
                      >
                        When and how do I get my tokens?
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={2}
                        pb={3}
                        fontSize="17px"
                        textAlign="left"
                        fontWeight="300"
                        color="#ffffff"
                      >
                        The PreSale ends on 12/04/2023, but after purchase you can directly claim your tokens and if you want use our staking app.
                        <br/>
                        <br />If you purchased in our alternative purchase app, we will automatically send you the tokens to the address you provide in your Account (directly during approval).
                        <br/><br/><u>BSC buyers:</u> A claiming button appear after the purchase. The tokens can be staked directly after claiming.
                        <br/><br/><u>Buyers on Ethereum:</u> After the purchase, switch to the BNB/BSC blockchain. Your claiming button will then appear there.
                        <br/><br/>Alternatively, you can join our Telegram group. If you want, we can send you your SURF tokens manually.

                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box borderRadius="5px">
                  <Accordion
                    expanded={expanded === `{panel2}`}
                    onChange={handleChange(`{panel2}`)}
                    style={{
                      borderRadius: "15px",
                      background: "#151b22",
                      width: "100%",
                      m: "auto",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `{panel2}` ? (
                          <Remove
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        ) : (
                          <Add
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        )
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "15px" : "20px"}
                        fontWeight={600}
                        px={2}
                        py={1}
                        color="#ffffff"
                      >
                        How i can add the SURF token to Metamask?
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={2}
                        pb={3}
                        fontSize="17px"
                        textAlign="left"
                        fontWeight="300"
                        color="#ffffff"
                      >
                        <span style={{ color: "#fde847", cursor: "pointer" }} onClick={() => addTokenToWallet()}> Click here</span>{" "}
                        or add our contract address in your Wallet. Please note that you must be connected to the Binance Smart Chain.

                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box borderRadius="5px">
                  <Accordion
                    expanded={expanded === `{panel3}`}
                    onChange={handleChange(`{panel3}`)}
                    style={{
                      borderRadius: "15px",
                      background: "#151b22",
                      width: "100%",
                      m: "auto",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `{panel3}` ? (
                          <Remove
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        ) : (
                          <Add
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        )
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "15px" : "20px"}
                        fontWeight={600}
                        px={2}
                        py={1}
                        color="#ffffff"
                      >
                        When will the SURF Token be listed?
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={2}
                        pb={3}
                        fontSize="17px"
                        textAlign="left"
                        fontWeight="300"
                        color="#ffffff"
                      >
                        The SURF Token will be listed shortly after the PreSale. The exact date will be announced.
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box borderRadius="5px">
                  <Accordion
                    expanded={expanded === `{panel4}`}
                    onChange={handleChange(`{panel4}`)}
                    style={{
                      borderRadius: "15px",
                      background: "#151b22",
                      width: "100%",
                      m: "auto",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `{panel4}` ? (
                          <Remove
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        ) : (
                          <Add
                            style={{
                              color: "#000000",
                              fontSize: "25px",
                              background: "#ffffff",
                              // padding: "10px",
                              borderRadius: "100px"
                            }}
                          />
                        )
                      }
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Box
                        fontSize={matches ? "15px" : "20px"}
                        fontWeight={600}
                        px={2}
                        py={1}
                        color="#ffffff"
                      >
                        Where will the SURF Token be listed?
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        pl={2}
                        pb={3}
                        fontSize="17px"
                        textAlign="left"
                        fontWeight="300"
                        color="#ffffff"
                      >
                        The SURF Token will be listed on DEX (decentralised exchanges), such as Pancakeswap, and CEX (centralised exchanges). We will announce the exact list of our partner exchanges and the date shortly.
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default FAQ;
