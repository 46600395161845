import {
  Button,
  Container,
  InputBase,
  useMediaQuery,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import moment from "moment";
import React, { useState, useContext } from "react";
import { AppContext } from "../utils";
import SouthIcon from "@mui/icons-material/South";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { getAccount } from "@wagmi/core";
import { useNetwork } from "wagmi";
import { useSwitchNetwork } from "wagmi";
import Loading from "../components/SmallComponents/loading";
import { useEffect } from "react";
import { bgLogo, logo2 } from "../components/SmallComponents/Images";
import { formatUnits, parseUnits } from "viem";
import {
  stakingReadFunction,
  stakingTokenReadFunction,
  stakingTokenWriteFunction,
  stakingWriteFunction,
} from "../ConnectivityAssets/hooks";
import { stakingAddress } from "../ConnectivityAssets/environment";
import {
  StyledText,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import NetworkSwitch from "../NetworkSwitch";
import { stakingMultiReadFunction } from "../ConnectivityAssets/multiNetwork";

function Staking() {
  const matches = useMediaQuery("(max-width:700px)");
  const { chain } = useNetwork();
  const { address } = getAccount();
  // let address = "0x14e8F2cee90B6f6bba6882D664b3ee676Dbe5EF4"
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();
  let connectedChainId = chain?.id;
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const [amount, setamount] = useState("");
  const [loading, setloading] = useState(false);
  const [aprPercentage, setaprPercentage] = useState(0);
  const [percentDivider, setpercentDivider] = useState(0);
  const [bonus, setbonus] = useState(0);
  const [balance, setbalance] = useState(0);
  const [TotalStakers, setTotalStakers] = useState(0);
  const [totalStaked, settotalStaked] = useState(0);
  const [totalUnStaked, settotalUnStaked] = useState(0);
  const [totalWithDrawn, settotalWithDrawn] = useState(0);
  const [userStaked, setuserStaked] = useState(0);
  const [userStakedToShow, setuserStakedToShow] = useState(0);
  const [userClaimAble, setuserClaimAble] = useState(0);
  const [stakeTime, setstakeTime] = useState(0);
  const [callFunction, setCallFunction] = useState(true);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  useEffect(() => {
    if (address && chain && chain?.id !== 56) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, address]);
  useEffect(() => {
    if (address) {
      (async () => {
        try {
          let surfBal = await stakingTokenReadFunction("balanceOf", [address]); 
          surfBal = Math.trunc(+formatUnits(surfBal.toString(), 18) * 100) / 100; 
          setbalance(+surfBal);
          const userData = await stakingReadFunction("stakers", [address]);
          let staked = parseFloat(
            +formatUnits(userData[0].toString(), 18)
          ).toFixed(0);
          console.log(toLocalFormat(staked), "staked");
          setuserStakedToShow(toLocalFormat(staked));
          setuserStaked(staked);
          let time = +userData[2].toString();
          setstakeTime(time);
          const userReward = await stakingReadFunction("calculateReward", [
            address,
          ]);
          let reward = parseFloat(
            +formatUnits(userReward.toString(), 18)
          ).toFixed(3);
          console.log(toLocalFormat(reward), "reward");
          setuserClaimAble(toLocalFormat(reward));
          setCallFunction(false);
        } catch (error) {
          setCallFunction(false);
          console.log(error);
        }
      })();
    }
  }, [callFunction, address, connectedChainId]);
  useEffect(() => {
    (async () => {
      try {
        const unstaked = await stakingMultiReadFunction("totalUnstaked");

        let unstakedValue = parseFloat(
          +formatUnits(unstaked.toString(), 18)
        ).toFixed(2);

        settotalUnStaked(toLocalFormat(+unstakedValue));
        settotalWithDrawn(toLocalFormat(+unstakedValue));
        const locked = await stakingMultiReadFunction("totalStaked");
        let lockedValue = parseFloat(
          +formatUnits(locked.toString(), 18)
        ).toFixed(2);
        lockedValue = +lockedValue - +unstakedValue;
        settotalStaked(toLocalFormat(+lockedValue));
        const stakers = await stakingMultiReadFunction("totalStakers");
        setTotalStakers(+stakers.toString());
        const apr = await stakingMultiReadFunction("initialApr");
        const perDivider = await stakingMultiReadFunction("PERCENT_DIVIDER");
        setpercentDivider(+perDivider.toString());
        setaprPercentage(+apr.toString());
        setCallFunction(false);
      } catch (error) {
        setCallFunction(false);
        console.log(error);
      }
    })();
  }, [callFunction]);
  const stakeHadler = async () => {
    if (address) {
      if (!amount) {
        setAlertState({
          open: true,
          message: `Error! Please enter a amount`,
          severity: "error",
        });
      } else if (amount <= 0) {
        setAlertState({
          open: true,
          message: `Error! Please enter a valid amount`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          await stakingTokenWriteFunction("approve", [
            stakingAddress,
            parseUnits(amount.toString(), 18).toString(),
          ]);

          console.log("CALLED_STAKING");
          await stakingWriteFunction("stake", [
            parseUnits(amount.toString(), 18).toString(),
          ]);
          setAlertState({
            open: true,
            message: "Success! Transaction confirmed",
            severity: "success",
          });
          setamount("");
          setCallFunction(true);
          setloading(false);
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  useEffect(() => {
    const init = async () => {
      try {
        let userBonus = (+amount * +aprPercentage) / +percentDivider;
        userBonus = +amount + userBonus;
        setbonus(+userBonus);
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, [amount, connectedChainId]);

  const claimHandler = async () => {
    try {
      setloading(true);
      await stakingWriteFunction("claim");
      setAlertState({
        open: true,
        message: "Success! Transaction confirmed",
        severity: "success",
      });
      setloading(false);
    } catch (error) {
      setloading(false);
      setAlertState({
        open: true,
        message: error?.shortMessage,
        severity: "error",
      });
    }
  };
  const unstakeHandler = async () => {
    try {
      setloading(true);
      await stakingWriteFunction("unstake");
      setAlertState({
        open: true,
        message: "Success! Transaction confirmed",
        severity: "success",
      });
      setloading(false);
    } catch (error) {
      setloading(false);
      setAlertState({
        open: true,
        message: error?.shortMessage,
        severity: "error",
      });
    }
  };

  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box
        py={2}
        position="relative"
        zIndex={1}
        sx={{
          backgroundImage: `url(${bgLogo})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <Loading loading={loading} />
        <div className="content-centered text-lg _52ch">
          <p></p>
          <center>
              <font color="yellow">Welcome to our staking app. <br/>If you have any questions, please let us know. And now: Stake it and enjoy it!</font><br/><br/> </center>
          <p />
        </div>
        <Container maxWidth="md">
                  <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  p={2}
                  width={matches ? "300px" : "500px"}
                  bgcolor="rgb(37, 99, 235)"
                  boxShadow="0px 0px 5px 1px #0D1117"
                  style={{
                    fontSize: matches ? "20px" : "30px",
                    fontWeight: "700",
                    color: "#ffffff",
                    textAlign: "center",
                    letterSpacing: "5px",
                    textTransform: "uppercase",
                    borderRadius: "20px",
                  }}
                >
                  Dashboard
                </Box>
              </Box>
              <Box
                bgcolor="#ffffff"
                height="100%"
                zIndex="10"
                p={2}
                mt={-4}
                className="shadow"
                borderRadius="20px"
              >
                <Box
                  p={matches ? 2 : 3}
                  height="100%"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    mt={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      fontFamily="Inter Display"
                      fontWeight="400"
                      fontSize="15px"
                      zIndex={2}
                      style={{
                        cursor: "pointer",
                        color: "#0D1117",
                        letterSpacing: "2px",
                        textTransform: "uppercase",
                      }}
                    >
                      Your balance
                    </Box>
                    <Box
                      fontFamily="Inter Display"
                      fontWeight="700"
                      fontSize="15px"
                      zIndex={2}
                      style={{
                        cursor: "pointer",
                        color: "#C32EF5",
                        letterSpacing: "2px",
                        textTransform: "uppercase",
                      }}
                    >
                      <span style={{ color: "#4473FA", fontSize: "20px" }}>
                        {balance}
                      </span>{" "}
                      SURF
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={1}
                    bgcolor="#dfdfdf"
                    boxShadow="3px 5px 5px 1px gray"
                    style={{
                      borderRadius: "10px",
                    }}
                    width="100%"
                    pb={1}
                    mt={1}
                  >
                    <InputBase
                      style={{
                        color: "#0D1117",
                        fontFamily: "Inter Display",
                        fontWeight: 400,
                        fontSize: 17,
                        marginTop: 10,
                        width: "100%",
                        paddingRight: "15px",
                        backgroundColor: "transparent",
                        paddingLeft: "15px",
                      }}
                      fullWidth
                      type="number"
                      id="standard-basic"
                      variant="standard"
                      placeholder="Enter Stake Amount"
                      value={amount}
                      onChange={(e) => setamount(e.target.value)}
                    />
                    <Box
                      fontFamily="Inter Display"
                      fontWeight="700"
                      fontSize="20px"
                      zIndex={2}
                      mr={2}
                      pt={1}
                      style={{
                        cursor: "pointer",
                        color: "#0D1117",
                        textAlign: "center",
                        letterSpacing: "5px",
                        textTransform: "uppercase",
                      }}
                      onClick={() => setamount(balance)}
                    >
                      Max
                    </Box>
                  </Box>
                  <Box alignSelf="center" mt={2}>
                    <SouthIcon style={{ color: "#0D1117", fontSize: "27px" }} />
                  </Box>
                  {/* <Box
                    my={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    bgcolor="#dfdfdf"
                    boxShadow="3px 5px 5px 1px gray"
                    style={{
                      borderRadius: "10px",
                    }}
                    width="100%"
                    pb={1}
                  >
                    <InputBase
                      style={{
                        color: "#0D1117",
                        fontFamily: "Inter Display",
                        fontWeight: 400,
                        fontSize: 17,
                        marginTop: 10,
                        width: "100%",
                        paddingRight: "15px",
                        backgroundColor: "transparent",
                        paddingLeft: "15px",
                      }}
                      readOnly
                      fullWidth
                      type="text"
                      id="standard-basic"
                      variant="standard"
                      placeholder="0"
                      value={amount > 0 ? bonus : 0}
                    />
                    <Box
                      fontFamily="Inter Display"
                      fontWeight="700"
                      fontSize="20px"
                      mr={2}
                      pt={1}
                      style={{
                        cursor: "pointer",
                        color: "#0D1117",
                        textAlign: "center",
                        letterSpacing: "5px",
                        textTransform: "uppercase",
                      }}
                    >
                      <img width="30px" src={logo2} alt="" />
                    </Box>
                  </Box > */}
                  <Box
                    mb={2}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Box>Estimated Yield</Box>
                    <Box color="#4473FA" fontSize="30px">
                      APY {parseFloat(aprPercentage / 100).toFixed(0)}%
                    </Box>
                  </Box>
                  <Box
                    fontFamily="Inter Display"
                    fontWeight="400"
                    fontSize="15px"
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    zIndex={2}
                    style={{
                      cursor: "pointer",
                      color: "#0D1117",
                      letterSpacing: "2px",
                      textTransform: "uppercase",
                    }}
                  >
                    APY = Annual Percentage Yield
                    <br />
                    <Divider
                      sx={{
                        width: "60%",
                        height: "1px",
                        mb: 0.5,
                        background: "#4473FA",
                      }}
                    />
                    Monthly = APY / 12
                    <br />
                    <Divider
                      sx={{
                        width: "40%",
                        height: "1px",
                        mb: 0.5,
                        background: "#7458E8",
                      }}
                    />
                    Daily = APY / 365
                    <Divider
                      sx={{
                        width: "30%",
                        height: "1px",
                        mb: 0.5,
                        background: "#C32EF5",
                      }}
                    />
                  </Box>
                  <Box
                    mt={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {address ? (
                      <>
                        {+connectedChainId === 56 ? (
                          <Box
                            style={{
                              cursor: "pointer",
                            }}
                            width="200px"
                            height="50px"
                            bgcolor="#7458E8"
                            borderRadius="50px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="#ffffff"
                            fontWeight="600"
                            fontSize="18px"
                            fontFamily="Inter Display"
                            onClick={() => stakeHadler()}
                          >
                            Stake
                          </Box>
                        ) : (
                          <StyledText
                            onClick={() => {
                              switchNetwork?.(56);
                            }}
                          >
                            <a
                              href=""
                              style={{
                                cursor: "pointer",
                                color: "rgb(37, 99, 235)",
                              }}
                            >
                              Switch network to BSC
                            </a>
                          </StyledText>
                        )}
                      </>
                    ) : (
                      <Box
                        style={{
                          cursor: "pointer",
                        }}
                        width="130px"
                        height="42px"
                        bgcolor="#7458E8"
                        borderRadius="50px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="#ffffff"
                        fontWeight="600"
                        fontSize="18px"
                        fontFamily="Inter Display"
                        onClick={async () => {
                          await open();
                        }}
                      >
                        Connect
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>

          </Grid>
        </Container>
        <Container maxWidth="md">
          <Box
            mt={10}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Box
              p={2}
              width={matches ? "300px" : "500px"}
              sx={{
                fontSize: matches ? "20px" : "30px",
                fontFamily: "Inter Display",
                fontWeight: "700",
                color: "#ffffff",
                textAlign: "center",
                letterSpacing: "5px",
                textTransform: "uppercase",
                borderRadius: "20px",
                position: "relative",
                zIndex: 1,
                bgcolor: "rgb(37, 99, 235)",
                boxShadow: "0px 0px 5px 1px #0D1117",
              }}
            >
              Your Statistics
            </Box>

            <TableContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "#ffffff",
              }}
              mt={-4}
              className="shadow"
              borderRadius="20px"
              component={Box}
              color="#ffffff"
            >
              <Table
                aria-label="simple table"
                style={{
                  borderRadius: "10px",
                  marginTop: "30px",
                }}
                color="#0D1117"
              >
                <TableHead color="#0D1117">
                  <TableRow style={{ color: "#0D1117" }}>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      STAKED{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      REWARD{" "}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      {" "}
                      WITHDRAWAL TIME
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      {" "}
                      UNSTAKE
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      {" "}
                      CLAIM
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      {+userStaked > 0 ? userStakedToShow : "0.00"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      {userClaimAble}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#0D1117",
                        fontSize: matches ? "14px" : "18px",
                        fontFamily: "Inter Display",
                      }}
                    >
                      {+stakeTime > 0
                        ? moment.unix(stakeTime).format("lll")
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#ffffff",
                      }}
                    >
                      <button
                        onClick={() => unstakeHandler()}
                        disabled={+userStaked > 0 ? false : true}
                        style={{
                          background:
                            +userStaked > 0
                              ? "linear-gradient(45deg,#7458E8, #7458E8,#7458E8)"
                              : "linear-gradient(0deg, #d1d1d1 0%, #d1d1d1 100%)",
                          fontSize: "16px",
                          borderRadius: "50px",
                          width: "176px",
                          height: "45px",
                          textTransform: "capitalize",
                          fontWeight: "500",
                          fontFamily: "Inter Display",
                          borderStyle: "none",
                          color: +userStaked > 0 ? "#ffffff" : "gray",
                          padding: "10px 35px",
                          border: "2px solid #ffffff",
                          cursor: +userStaked > 0 ? "pointer" : "no-drop",
                        }}
                      >
                        Unstake
                      </button>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: "#ffffff",
                      }}
                    >
                      <button
                        onClick={() => claimHandler()}
                        disabled={+userStaked > 0 ? false : true}
                        style={{
                          background:
                            +userStaked > 0
                              ? "linear-gradient(45deg,#7458E8, #7458E8,#7458E8)"
                              : "linear-gradient(0deg, #d1d1d1 0%, #d1d1d1 100%)",
                          fontSize: "16px",
                          borderRadius: "50px",
                          width: "176px",
                          height: "45px",
                          textTransform: "capitalize",
                          fontWeight: "500",
                          fontFamily: "Inter Display",
                          borderStyle: "none",
                          color: +userStaked > 0 ? "#ffffff" : "gray",
                          padding: "10px 35px",
                          border: "2px solid #ffffff",
                          cursor: +userStaked > 0 ? "pointer" : "no-drop",
                        }}
                      >
                        Claim
                      </button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Staking;
