import React, { useEffect } from "react";

function Jobs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="section bg-tile-middle-lg bg-indigo-900">
        <div className="relative">
          <div className="section more-space less">
            <div className="container-lg-2 content-centered">
              <div className="margin-bottom margin-4xl">
                <div className="content-centered">
                  <h1 className="title-2xl-2">
                    We are looking forward
                    <br />
                    to <span className="text-gradient__purple-2">you</span>.
                  </h1>
                </div>
              </div>
              <div className="text-lg-2 _36ch">
                Discover active job offers and positions
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container-lg-2">
          <div className="w-layout-grid side-by-side-2-to-3">
            <div id="w-node-_4372e0d0-fd8f-ef2d-5026-d963f9eefaa4-9a9647ea">
              <div className="w-layout-grid stellenanzeige fade-in-on-scroll">
                <h6
                  id="w-node-_4372e0d0-fd8f-ef2d-5026-d963f9eefaa6-9a9647ea"
                  className="font-title-2 leading-none"
                >
                  Open positions:
                </h6>
              </div>
              <div className="div-block-295">
                <div className="div-block-296">
                  <div
                    id="w-node-_386b1d23-a8ff-6a7f-cb92-28dfdf914b15-9a9647ea"
                    className="div-block-297"
                  >
                    <div className="div-block-298">
                      <a
                        id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bb2-9a9647ea"
                        data-w-id="5a6ba1bc-e691-c986-37e6-df1cfa814bb2"
                        href="#"
                        className="text-link-2 w-inline-block"
                      >
                        <div
                          id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bb3-9a9647ea"
                          className="w-layout-grid column-sm-2"
                        >
                          <div
                            id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bb4-9a9647ea"
                            className="w-layout-grid row-xs"
                          >
                            <h5
                              id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bb5-9a9647ea"
                              className="font-title-2 leading-none"
                            >
                              JavaScript Developer (m/f)
                            </h5>
                          </div>
                          <div
                            id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bb9-9a9647ea"
                            className="w-layout-grid row-xs"
                          >
                            <div
                              id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bba-9a9647ea"
                              className="text-xs"
                            >
                              Full-time
                            </div>
                            <div
                              id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bbc-9a9647ea"
                              className="text-xs"
                            >
                              ·
                            </div>
                            <div
                              id="w-node-_5a6ba1bc-e691-c986-37e6-df1cfa814bbe-9a9647ea"
                              className="text-xs"
                            >
                              Düsseldorf, Germany
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6cea-9a9647ea"
                        data-w-id="34a5c1ad-91bc-a3ab-827b-e754668c6cea"
                        href="#"
                        className="text-link-2 w-inline-block"
                      >
                        <div
                          id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6ceb-9a9647ea"
                          className="w-layout-grid column-sm-2"
                        >
                          <div
                            id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6cec-9a9647ea"
                            className="w-layout-grid row-xs"
                          >
                            <h5
                              id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6ced-9a9647ea"
                              className="font-title-2 leading-none"
                            >
                              Marketing &amp; Sales Employees (m/f)
                            </h5>
                          </div>
                          <div
                            id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6cf1-9a9647ea"
                            className="w-layout-grid row-xs"
                          >
                            <div
                              id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6cf2-9a9647ea"
                              className="text-xs"
                            >
                              Full-time
                            </div>
                            <div
                              id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6cf4-9a9647ea"
                              className="text-xs"
                            >
                              ·
                            </div>
                            <div
                              id="w-node-_34a5c1ad-91bc-a3ab-827b-e754668c6cf6-9a9647ea"
                              className="text-xs"
                            >
                              Düsseldorf, Germany
                            </div>
                          </div>
                        </div>
                      </a>
                      <a
                        id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de3415-9a9647ea"
                        data-w-id="202bc8ec-5c8a-fd34-279c-6ba803de3415"
                        href="#"
                        className="text-link-2 w-inline-block"
                      >
                        <div
                          id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de3416-9a9647ea"
                          className="w-layout-grid column-sm-2"
                        >
                          <div
                            id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de3417-9a9647ea"
                            className="w-layout-grid row-xs"
                          >
                            <h5
                              id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de3418-9a9647ea"
                              className="font-title-2 leading-none"
                            >
                              Customer service (m/f)
                            </h5>
                          </div>
                          <div
                            id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de341c-9a9647ea"
                            className="w-layout-grid row-xs"
                          >
                            <div
                              id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de341d-9a9647ea"
                              className="text-xs"
                            >
                              Full-time
                            </div>
                            <div
                              id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de341f-9a9647ea"
                              className="text-xs"
                            >
                              ·
                            </div>
                            <div
                              id="w-node-_202bc8ec-5c8a-fd34-279c-6ba803de3421-9a9647ea"
                              className="text-xs"
                            >
                              Düsseldorf, Germany
                            </div>
                          </div>
                        </div>
                      </a>
                      <div className="container-md">
                        <div>
                          If you are interested, you can request the exact job
                          description by email to jobs@surfreward.io. Please
                          note that the place of work is Düsseldorf and home
                          office is only possible to a limited extent.
                          Furthermore, English and German language skills are
                          required.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Jobs;
