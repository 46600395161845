import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import About from "./pages/About";
import Partners from "./pages/Partners";
import Video from "./pages/Video";
import HowItWorks from "./pages/HowItWorks";
import PartnersTwo from "./pages/PartnersTwo";
import Roadmap from "./pages/Roadmap";
import Team from "./pages/Team";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import FAQ from "./pages/Faq/FAQ";
import { Route, Routes } from "react-router-dom";
import Staking from "./pages/staking";
import Jobs from "./pages/Jobs";

function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    if (account && chain && chain?.id !== 56 && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  useEffect(() => {
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, []);
  return (
    <div>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <About />
              <Partners />
              <Video />
              <HowItWorks />
              <PartnersTwo />
              <Roadmap />
              <Team />
              <FAQ />
            </>
          }
        />
        <Route path="/stake" element={<Staking />} />
        <Route path="/jobs" element={<Jobs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
