import React, { createContext, useEffect, useState } from "react";
import { useAccount, useWalletClient, useNetwork } from "wagmi";

/// start url

export const url =
  process.env.NODE_ENV === "production"
    ? "https://surfreward-9b6a6b901ba1.herokuapp.com"
    : "http://localhost:3000";

/// end url

let initialState = {
  account: null,
  signer: null,
  chainId: [],
  singleChainId: 0,
};

export const AppContext = createContext(initialState);
export const ConectivityProvider = ({ children }) => {
  const { address, isDisconnected } = useAccount();
  const { data: signer } = useWalletClient();
  const { chains, chain } = useNetwork();
  const chainId = chains?.map((info) => info?.id);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      account: address ?? null,
      signer: signer ?? null,
    });
  }, [isDisconnected, address, signer]);
  return (
    <AppContext.Provider
      value={{
        account: state.account,
        signer: state.signer,
        chainId: chainId,
        singleChainId: chain?.id,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
