import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Header() {
  const { pathname } = useLocation();
  // const [activeSection, setActiveSection] = useState("");

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPos = window.scrollY;

  //     if (scrollPos > 100) {
  //       let header = document.getElementById("headerId");
  //       let headerTwo = document.getElementById("headerIdTwo");
  //       header.classList.add("fixed-head");
  //       headerTwo.classList.add("fixed-head-two");
  //     } else {
  //       let header = document.getElementById("headerId");
  //       let headerTwo = document.getElementById("headerIdTwo");
  //       header.classList.remove("fixed-head");
  //       headerTwo.classList.remove("fixed-head-two");
  //     }
  //     // Find the active section based on scroll position
  //     const sections = document.querySelectorAll("section");
  //     for (const section of sections) {
  //       const sectionTop = section.offsetTop;
  //       const sectionBottom = sectionTop + section.offsetHeight;

  //       if (scrollPos >= sectionTop && scrollPos < sectionBottom) {
  //         setActiveSection(section.getAttribute("id"));
  //         break;
  //       }
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <div>
      <div id={"headerId"} className="sticky-info-wrapper">
        <div className="sticky-info-flex-child">
          <div className="container-lg sticky-info">
            <div
              data-w-id="afdb5a19-3eb4-3fa5-6cf7-bb479a2cf917"
              className="good-news-text"
            >
              <center>Welcome to SURF Reward - Biggest PreSale 2023! What do you think?
              100x or more!?</center>
            </div>
          </div>
        </div>
      </div>
      <div
        id={"headerIdTwo"}
        data-collapse="medium"
        data-animation="default"
        data-duration={0}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div
          className="w-layout-grid navbar-list "
          style={{ gridTemplateColumns: "auto auto auto" }}
        >
          <Link to="/">
            <a aria-current="page" className="brand w-nav-brand w--current">
              <img
                src="images/Logo2_transparent_backgroundBG_.png"
                loading="lazy"
                alt=""
                className="image-contain"
              />
            </a>
          </Link>
          <div className="dropdown ml-auto pc-style">
           
                
                <button className="button-animated small w-inline-block dropbtn">
                  <a href="#presale">
                    Buy now
                  </a>
                 
                </button>
          
          </div>
          <div className="other-links w-nav-menu">
            <nav className="header_navigation">
              {pathname === "/stake" || pathname === "/jobs" ? (
                <>
                  <HashLink
                    smooth
                    to="/#about"
                    style={{ textDecoration: "none" }}
                    className="menu-close"
                  >
                    What is SURF?
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#how-it-works"
                    style={{ textDecoration: "none" }}
                    className="menu-close"
                  >
                    How it works
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#specials"
                    style={{ textDecoration: "none" }}
                    className="menu-close"
                  >
                    Bonus Specials
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#roadmap"
                    style={{ textDecoration: "none" }}
                    className="menu-close"
                  >
                    Roadmap
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#team"
                    style={{ textDecoration: "none" }}
                    className="menu-close"
                  >
                    Team
                  </HashLink>
                  <HashLink
                    smooth
                    to="/#contact-us"
                    style={{ textDecoration: "none" }}
                    className="menu-close"
                  >
                    Contact
                  </HashLink>
                </>
              ) : (
                <>
                  <a className="menu-close" href="#about">What is SURF?</a>
                  <a className="menu-close" href="#how-it-works">How it works</a>
                  <a className="menu-close" href="#specials">Bonus Specials</a>
                  <a className="menu-close" href="#roadmap">Roadmap</a>
                  <a className="menu-close" href="#team">Team</a>
                  <a className="menu-close" href="#contact-us">Contact</a>
                </>
              )}
            </nav>
            <Link
              to="/stake"
              style={{
                textDecoration: "none",
                textAlign: "left",
              }}
              className="menu-close"
            >
              Staking
            </Link>
            <Link
              to="/jobs"
              style={{
                textDecoration: "none",
                textAlign: "left",
              }}
              className="menu-close"
            >
              Jobs
            </Link>
            {/* <a href="jobs.html">Jobs</a> */}
            <a
              style={{ textAlign: "left" }}
              href="https://docs.surfreward.io/"
              target="_blank"
              className="menu-close"
            >
              Demo
            </a>
          </div>
          <div
            id="w-node-_90910534-8503-0140-553b-dd5cfa0cbf37-fa0cbf10"
            className="navbar-controls"
          >
            <div className="hide-mobile">
              <div id="w-node-cd49f020-3201-373d-67b5-0238b163a288-052a211e">
                <a
                  id="w-node-cd49f020-3201-373d-67b5-0238b163a289-052a211e"
                  href="#presale"
                  className="button-animated small w-inline-block dropbtn"
                >
                  <div className="button-label">Buy now</div>
                  <div className="button-hover-background" />
                </a>
              </div>
            </div>
            <div
              data-w-id="90910534-8503-0140-553b-dd5cfa0cbf3d"
              className="menu-button w-nav-button"
            >
              <div className="menu-button-wrap">
                <div className="menu-open">drag_handle</div>
                <div className="menu-close">close</div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-shadow" />
      </div>
    </div>
  );
}

export default Header;
