import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  InputBase,
  Slide,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AppContext } from "../utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CSVLink } from "react-csv";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import { formatUnits, parseUnits, encodeFunctionData } from "viem";
import { useNetwork } from "wagmi";
import { fetchBalance } from "@wagmi/core";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import testGoreliAbi from "../ConnectivityAssets/testGoreliAbi.json";
import { getAccount } from "@wagmi/core";
import { useSwitchNetwork } from "wagmi";
import {
  StyledButton,
  StyledCurrencyButton,
  StyledText,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import { bnb, eth, logo2, usdt } from "../components/SmallComponents/Images";
import TimerCountDown from "../components/SmallComponents/PresaleTimer";
import {
  admin,
  bscPresaleAddress,
  defaultAddress,
  ethPresaleAddress,
} from "../ConnectivityAssets/environment";
import {
  bnbPresaleMultiReadFunction,
  ethPresaleMultiReadFunction,
} from "../ConnectivityAssets/multiNetwork";
import {
  bnbPresaleReadFunction,
  bnbPresaleWriteFunction,
  bnbUsdtReadFunction,
  bnbUsdtWriteFunction,
  ethPresaleReadFunction,
  ethPresaleWriteFunction,
  ethUsdtReadFunction,
  ethUsdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import Loading from "../components/SmallComponents/loading";
import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer/";
import { LineAxisOutlined } from "@mui/icons-material";
import axios from "axios";
import { url } from "../utils";
import moment from "moment";

const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    height: "40px",
    fontSize: "18px",
    fontWeight: "400",
    textAlign: "left",
    color: "#000000",
    backgroundColor: "transparent",
    paddingLeft: "15px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HeroSection() {
  window.Buffer = Buffer;
  const { singleChainId, chainId } = useContext(AppContext);
  const { chain } = useNetwork();
  const { address } = getAccount();
  const { open } = useWeb3Modal();
  const { switchNetwork } = useSwitchNetwork();
  let connectedChainId = chain?.id;
  const matches = useMediaQuery("(max-width:700px)");
  const [web3ChainId, setweb3ChainId] = useState(0);
  const [token, setToken] = useState("USDT");
  const [enteredAmount, setEnteredAmount] = useState("");
  const [balanceUSDT, setbalanceUSDT] = useState(0);
  const [balanceETH, setbalanceETH] = useState(0);
  const [balanceBNB, setbalanceBNB] = useState(0);
  const [receivedTokens, setreceivedTokens] = useState("");
  const [raisedAmount, setRaisedAmount] = useState("");
  const [progressBar, setProgessBar] = useState(0);
  const [loading, setloading] = useState(false);
  const [hardCap, sethardCap] = useState(0);
  const [totalSupplyPerPhase, settotalSupplyPerPhase] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [soldTokens, setsoldTokens] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [csvAmounts, setcsvAmounts] = useState([]);
  const [csvAddresses, setcsvAddresses] = useState([]);
  const [nextStagePrice, setnextStagePrice] = useState(0);
  const [presaleStage, setpresaleStage] = useState(0);
  const [oneUSDTtoToken, setoneUSDTtoToken] = useState(0);
  const [oneETHtoToken, setoneETHtoToken] = useState(0);
  const [oneBNBtoToken, setoneBNBtoToken] = useState(0);
  const [isClaimEnabled, setisClaimEnabled] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [totalSupply, settotalSupply] = useState(0);
  const [totalSoldAmountApi, setTotalSoldAmountApi] = useState(0);
  const [apiResponseState, setApiResponseState] = useState(false);
  const [userPurchasedTokensETH, setuserPurchasedTokensETH] = useState(0);
  const [userPuchasedTokensToShow, setuserPuchasedTokensToShow] = useState(0);
  const [totalPurchasedTokens, settotalPurchasedTokens] = useState(0);
  const [count, setCount] = useState(0);
  const [inputSrc, setInputSrc] = useState("");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  let currentTime = moment().format("X");
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  /// wert section

  useEffect(() => {
    if (address) {
      (async () => {
        let amount = enteredAmount ? enteredAmount : 1;
        const sc_input_data = encodeFunctionData({
          abi: testGoreliAbi,
          functionName: "buyToken",
          args: [defaultAddress],
          value: parseUnits(amount.toString(), 18).toString(),
        });
        setInputSrc(sc_input_data);
      })();
    }
  }, [address, enteredAmount]);

  const privateKey =
    "0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3";

  const signedData = signSmartContractData(
    {
      address: address,
      commodity: "ETH",
      network: "goerli",
      commodity_amount: enteredAmount ? parseFloat(enteredAmount) : 0,
      sc_address: "0xa572c101309Eef3a4044C57623144e0CA9128252",
      sc_input_data: inputSrc,
    },
    privateKey
  );
  const wertOption = {
    partner_id: "01H8J5E65Q1WBW08NEAWGENX5G",
    // partner_id: "01GCRJZ1P7GP32304PZCS6RSPD",
    click_id: uuidv4(), // unique id of purhase in your system
    origin: "https://sandbox.wert.io", // this option needed only for this example to work
  };

  const wertWidget = new WertWidget({
    ...signedData,
    ...wertOption,
  });

  /// end wert section

  useEffect(() => {
    (() => {
      setweb3ChainId(chain?.id);
      if (window.location.href.includes("?ref=")) {
        let getAddress = window.location.href.split("?ref=")[1];
        let final = getAddress.slice(0, 42);
        localStorage.setItem("SURF", final);
      }
    })();
  }, [connectedChainId, address, chain]);

  ///call api
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${url}/total-sold-amount`);
        setTotalSoldAmountApi(data?.soldAmount ?? 0);
        setApiResponseState(true);
      } catch (error) {
        setTotalSoldAmountApi(0);
        setApiResponseState(true);
        console.log(error);
      }
    })();
  }, []);

  // // VoidSigner Valuse get from contracts
  const multiInit = async () => {
    try {
      const nsp = await bnbPresaleMultiReadFunction("nextPrice");
      setnextStagePrice(+formatUnits(nsp.toString(), 18));
      const saleStage = await bnbPresaleMultiReadFunction("StageCount");
      setpresaleStage(+saleStage.toString());
      let perPhaseSupplyETH = await bnbPresaleMultiReadFunction("tokenForSell");
      settotalSupplyPerPhase(+formatUnits(perPhaseSupplyETH.toString(), 18));
      let price = await bnbPresaleMultiReadFunction("tokenPerUsd");
      settokenPrice(+formatUnits(price.toString(), 18));
      price = +formatUnits(price.toString(), 18);
      const rec1 = await bnbPresaleMultiReadFunction("usdtToToken", [
        "1000000000000000000",
      ]);
      const rec3 = await bnbPresaleMultiReadFunction("NativeToToken", [
        "1000000000000000000",
      ]);
      setoneBNBtoToken(+formatUnits(rec3.toString(), 18));
      setoneUSDTtoToken(+formatUnits(rec1.toString(), 18));
      const rec2 = await ethPresaleMultiReadFunction("NativeToToken", [
        "1000000000000000000",
      ]);
      setoneETHtoToken(+formatUnits(rec2.toString(), 18));
      const supplyTotal = await bnbPresaleMultiReadFunction("totalSupply");
      const soldTotalBNB = await bnbPresaleMultiReadFunction("soldToken");
      const soldTotalETH = await ethPresaleMultiReadFunction("soldToken");

      let total =
        +formatUnits(soldTotalETH.toString(), 18) +
        +formatUnits(soldTotalBNB.toString(), 18);
      total = parseFloat(total).toFixed(0);
      setsoldTokens(toLocalFormat(total));
      let hardCap = +formatUnits(perPhaseSupplyETH.toString(), 18) / +price;

      hardCap = parseFloat(hardCap).toFixed(0);
      sethardCap(toLocalFormat(hardCap));
      let supp = +formatUnits(perPhaseSupplyETH.toString(), 18);
      supp = parseFloat(supp).toFixed(0);
      settotalSupply(toLocalFormat(supp));

      /////////////// Progress
      let passedDays = 1715030122  - +currentTime;
      passedDays = +passedDays / 86400;

      passedDays = 7 - +passedDays;
      let prog = (+passedDays / 7) * 100;
      setProgessBar(+prog);

      ///////////// Raised Total

      const raised = await bnbPresaleMultiReadFunction("amountRaised");
      let usdtRaisedBSC = await bnbPresaleMultiReadFunction("amountRaisedUSDT");
      usdtRaisedBSC = +formatUnits(usdtRaisedBSC.toString(), 18);
      const currentPriceBNB = await bnbPresaleMultiReadFunction(
        "getLatestPrice"
      );

      let bnbToUsdt =
        +formatUnits(raised.toString(), 18) *
        +formatUnits(currentPriceBNB.toString(), 8);
      const ethRaised = await ethPresaleMultiReadFunction("amountRaised");
      let usdtRaisedETH = await ethPresaleMultiReadFunction("amountRaisedUSDT");
      usdtRaisedETH = +formatUnits(usdtRaisedETH.toString(), 6);
      const ethPrice = await ethPresaleMultiReadFunction("getLatestPrice");
      let ethToUsdt =
        +formatUnits(ethRaised.toString(), 18) *
        +formatUnits(ethPrice.toString(), 8);

      let totalRaised = apiResponseState
        ? +ethToUsdt +
          +bnbToUsdt +
          +usdtRaisedBSC +
          +usdtRaisedETH +
          +totalSoldAmountApi
        : "";

      totalRaised = parseFloat(totalRaised).toFixed(0);
      setRaisedAmount(toLocalFormat(totalRaised));
      const claimEnabled = await bnbPresaleMultiReadFunction("enableClaim");
      setisClaimEnabled(claimEnabled);
      if (address) {
        const userBNB = await bnbPresaleMultiReadFunction("users", [address]);
        const userETH = await ethPresaleMultiReadFunction("users", [address]);
        let totalToken =
          +formatUnits(userBNB[2].toString(), 18) +        
          +formatUnits(userETH[2].toString(), 18);
        totalToken = parseFloat(totalToken).toFixed(0);
        settotalPurchasedTokens(totalToken);
        setuserPuchasedTokensToShow(toLocalFormat(totalToken));
      }
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    multiInit();
  }, [address, callFunction, apiResponseState]);

  useEffect(() => {
    if (address) {
      (async () => {
        try {
          if (+connectedChainId === 56) {
            const bnbBal = await fetchBalance({
              address: address,
            });
            let balBNB = parseFloat(
              +formatUnits(bnbBal?.value?.toString(), 18)
            ).toFixed(3);
            setbalanceBNB(toLocalFormat(+balBNB));
            const balance = await bnbUsdtReadFunction("balanceOf", [address]);
            let usdtBal = parseFloat(
              +formatUnits(balance.toString(), 18)
            ).toFixed(2);
            setbalanceUSDT(toLocalFormat(+usdtBal));

            const user = await bnbPresaleReadFunction("users", [address]);
            setuserPurchasedTokens(+formatUnits(user[2].toString(), 18));

            const purchasedWL = await bnbPresaleReadFunction("wallets", [
              address,
            ]);
            setuserPurchasedTokensETH(+formatUnits(purchasedWL.toString(), 18));
          }
          if (+connectedChainId === 1) {
            const walletBalance = await fetchBalance({
              address: address,
            });
            let ethBal = parseFloat(
              +formatUnits(walletBalance?.value?.toString(), 18)
            ).toFixed(3);
            setbalanceETH(toLocalFormat(+ethBal));
            const balance = await ethUsdtReadFunction("balanceOf", [address]);
            let usdtBal = parseFloat(
              +formatUnits(balance.toString(), 6)
            ).toFixed(2);
            setbalanceUSDT(toLocalFormat(+usdtBal));
            const user = await ethPresaleReadFunction("users", [address]);
            let userTokens = parseFloat(
              +formatUnits(user[2].toString(), 18)
            ).toFixed(2);
            setuserPurchasedTokens(toLocalFormat(userTokens));
          }
          setCallFunction(false);
        } catch (error) {
          setCallFunction(false);
          console.log(error);
        }
      })();
    }
  }, [address, connectedChainId, callFunction]);

const buyHadler = async () => { 
    if (address) { 
      if (!enteredAmount) { 
        setAlertState({ 
          open: true, 
          message: `Error! Please enter a amount`, 
          severity: "error", 
        }); 
      } else if (enteredAmount <= 0) { 
        setAlertState({ 
          open: true, 
          message: `Error! Please enter a valid amount`, 
          severity: "error", 
        }); 
      } else { 
        try { 
          setloading(true); 
          let refAddress = localStorage.getItem("SURF"); 
          let refAdd = refAddress ? refAddress : defaultAddress; 
          if (connectedChainId === 56) { 
            if (token === "USDT") { 
              let allowance = await bnbUsdtReadFunction("allowance", [ 
                address, 
                bscPresaleAddress, 
              ]); 
              if (+formatUnits(allowance.toString()) < +enteredAmount) { 
                await bnbUsdtWriteFunction("approve", [ 
                  bscPresaleAddress, 
                  "9999999999999999999999999999999", 
                ]); 
              } 
              await bnbPresaleWriteFunction("buyTokenUSDT", [ 
                refAdd, 
                parseUnits(enteredAmount.toString(), 18).toString(), 
              ]); 
            } else { 
              await bnbPresaleWriteFunction( 
                "buyToken", 
                [refAdd], 
                parseUnits(enteredAmount.toString(), 18).toString() 
              ); 
            } 
          } else { 
            let refPercentage = (+receivedTokens * 5) / 100; 
            refPercentage = +receivedTokens + +refPercentage; 
            refPercentage = parseUnits(refPercentage.toString(), 18).toString(); 
            let addArr = []; 
            let amtArr = []; 
            addArr.push(address); 
            amtArr.push(refPercentage); 
            if (token === "USDT") { 
              await ethUsdtWriteFunction("approve", [ 
                ethPresaleAddress, 
                parseUnits(enteredAmount.toString(), 6).toString(), 
              ]); 
              await ethPresaleWriteFunction("buyTokenUSDT", [ 
                refAdd, 
                parseUnits(enteredAmount.toString(), 6).toString(), 
              ]); 
 
              await axios.post(`${url}/sendTransaction`, { 
                address: addArr, 
                receivedTokens: amtArr, 
              }); 
              console.log(addArr, "addArr", amtArr, "amtArr"); 
            } else { 
              await ethPresaleWriteFunction( 
                "buyToken", 
                [refAdd], 
                parseUnits(enteredAmount.toString(), 18).toString() 
              ); 
              console.log(addArr, "addArr", amtArr, "amtArr"); 
              await axios.post(`${url}/sendTransaction`, { 
                address: addArr, 
                receivedTokens: amtArr, 
              }); 
            } 
          } 
          setAlertState({ 
            open: true, 
            message: "Success! Transaction Confirmed", 
            severity: "success", 
          }); 
          setEnteredAmount(""); 
          setCallFunction(true); 
          multiInit(); 
          setloading(false); 
        } catch (error) { 
          setloading(false); 
          setAlertState({ 
            open: true, 
            message: error?.shortMessage, 
            severity: "error", 
          }); 
        } 
      } 
    } else { 
      setAlertState({ 
        open: true, 
        message: `Error! Please connect your wallet.`, 
        severity: "error", 
      }); 
    } 
  };
  useEffect(() => {
    const calculator = async () => {
      try {
        if (token === "USDT") {
          let tokenUSDT = +oneUSDTtoToken * +enteredAmount;
          setreceivedTokens(tokenUSDT);
        } else if (token === "ETH") {
          let tokenEth = +oneETHtoToken * +enteredAmount;
          setreceivedTokens(tokenEth);
        } else {
          let tokenbnb = +oneBNBtoToken * +enteredAmount;
          setreceivedTokens(tokenbnb);
        }
      } catch (error) {}
    };
    if (+enteredAmount > 0) {
      calculator();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredAmount, token]);
  //console.log("test", userPurchasedTokensETH);
  // console.log(+userPurchasedTokens, "userPurchasedTokens", userPurchasedTokensETH);
  const claimHandler = async () => {
    if (address) {
      if (!isClaimEnabled) {
        setAlertState({
          open: true,
          message: `You can claim and stake tokens after buying`,
          severity: "error",
        });
      } else if (+userPurchasedTokens <= 0 && +userPurchasedTokensETH <= 0) {
        setAlertState({
          open: true,
          message: `You have nothing to claim.`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);          
          if (+userPurchasedTokens > 0) {
            await bnbPresaleWriteFunction("claimTokens");
          }
          if (+userPurchasedTokensETH > 0) {
            await bnbPresaleWriteFunction("ClaimForEth");
          }
          setloading(false);
          setAlertState({
            open: true,
            message: `Transaction Completed!`,
            severity: "success",
          });
          setEnteredAmount("");
          setCallFunction(true);
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  const csvInit = async () => {
    try {
      if (+connectedChainId === 1 || +web3ChainId === 1) {
        const totalUser = await ethPresaleReadFunction("totalUsers");
        let csvAddressesArray = [];
        let csvAmountsArray = [];
        for (let i = 0; i < +totalUser.toString(); i++) {
          const addresses = await ethPresaleReadFunction("UsersAddresses", [
            i.toString(),
          ]);
          csvAddressesArray.push(addresses.toString());
          const user = await ethPresaleReadFunction("users", [addresses]);
          let userPurchasedTokens = +formatUnits(user[2].toString(), 18);
          userPurchasedTokens = parseFloat(userPurchasedTokens).toFixed(0);
          csvAmountsArray.push(userPurchasedTokens.toString());
        }
        setcsvAddresses([csvAddressesArray]);
        setcsvAmounts([csvAmountsArray]);
      }
    } catch (error) {
      console.log(error, "CSV ERROR");
    }
  };
  useEffect(() => {
    csvInit();
  }, [connectedChainId, web3ChainId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (+count === 2) {
        setCount(0);
      } else {
        setCount(count + 1);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [count]);
  return (
    <div id="presale" className="section-under-navbar bg-tile-top-lg bg-neutral-600">
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <div className="wrapper-p hero">
        <div className=" hero-content">
          <div
            data-w-id="eb8e827f-3c5d-1fcb-b2e8-3163db0751de"
            className="gradient-titles-wrap"
          >
            <div className="heading-wrapper">
              {+count === 0 ? (
                <h2
                  style={{
                    fontFamily: "Inter Display",
                    background: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundImage:
                      "linear-gradient(139deg, #2081d4, #d326f8 73%, #d524f7)",
                    fontSize: matches ? "6em" : "9em",
                    fontWeight: "800",
                    lineHeight: 1.2,
                    letterSpacing: "-2px",
                  }}
                >
                  SURF
                </h2>
              ) : (
                <h2
                  style={{
                    color: "#20262D",
                    fontFamily: "Inter Display",
                    fontSize: matches ? "6em" : "9em",
                    fontWeight: "800",
                    lineHeight: 1.2,
                    letterSpacing: "-2px",
                  }}
                >
                  SURF
                </h2>
              )}
            </div>
            <div className="heading-wrapper">
              {+count === 1 ? (
                <h2
                  style={{
                    fontFamily: "Inter Display",
                    marginTop: matches ? "-30px" : "-50px",
                    background: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundImage:
                      "radial-gradient(circle farthest-side at -25% -50%, #4338ca, rgba(255, 251, 235, 0)), linear-gradient(285deg, #7dd3fc, #ef4848)",
                    fontSize: matches ? "6em" : "9em",
                    fontWeight: "800",
                    lineHeight: 1.2,
                    letterSpacing: "-2px",
                  }}
                >
                  2
                </h2>
              ) : (
                <h2
                  style={{
                    color: "#20262D",
                    fontFamily: "Inter Display",
                    marginTop: matches ? "-30px" : "-50px",
                    fontSize: matches ? "6em" : "9em",
                    fontWeight: "800",
                    lineHeight: 1.2,
                    letterSpacing: "-2px",
                  }}
                >
                  2
                </h2>
              )}
            </div>
            <div className="heading-wrapper">
              {+count === 2 ? (
                <h2
                  style={{
                    fontFamily: "Inter Display",
                    marginTop: matches ? "-30px" : "-50px",
                    background: "transparent",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundImage:
                      "linear-gradient(141deg, #227ed2, #54ebb4 58%, #7cc3ff)",
                    fontSize: matches ? "6em" : "9em",
                    fontWeight: "800",
                    lineHeight: 1.2,
                    letterSpacing: "-2px",
                  }}
                >
                  EARN
                </h2>
              ) : (
                <h2
                  style={{
                    color: "#20262D",
                    fontFamily: "Inter Display",
                    marginTop: matches ? "-30px" : "-50px",
                    fontSize: matches ? "6em" : "9em",
                    fontWeight: "800",
                    lineHeight: 1.2,
                    letterSpacing: "-2px",
                  }}
                >
                  EARN
                </h2>
              )}
            </div>
          </div>
          <div className="container-md content-centered">
            <div className="margin-bottom margin-5xl">
              <div
                id="w-node-cd49f020-3201-373d-67b5-0238b163a282-052a211e"
                className="text-xl _40ch"
              >
               <font color="yellow">ATTENTION:<br/>surfrewards.online is a SCAM! We are surfreward.io!</font><br /><font color="red">Try our new BuyApp now <br/>and get a 5% bonus on every purchase!</font> <br/>Or use our <a href="https://buy.surfreward.io" target="_blank" rel="noreferrer"><u>old app.</u></a> It is also still active.
                              
                              
                <br />
              </div>
            </div> 
            <div
              id="w-node-cd49f020-3201-373d-67b5-0238b163a287-052a211e"
              className="w-layout-grid row-base column-mobile p"
            >
              <div id="w-node-cd49f020-3201-373d-67b5-0238b163a288-052a211e">
                <a
                  id="w-node-cd49f020-3201-373d-67b5-0238b163a289-052a211e"
                  href="SURF_whitepaper.pdf"
                  target="_blank"
                  className="button-animated large w-inline-block"
                >
                  <div className="button-label">Whitepaper</div>
                  <div className="button-hover-background" />
                </a>
              </div>
              <div id="w-node-cd49f020-3201-373d-67b5-0238b163a28a-052a211e">
                <a
                  href="SURF_Audit.pdf"
                  target="_blank"
                  className="button button-outline large w-button"
                >
                  Token Audit
                </a>
              </div>
              <div id="w-node-cd49f020-3201-373d-67b5-0238b163a288-052a211e">
                <a
                  id="w-node-cd49f020-3201-373d-67b5-0238b163a289-052a211e"
                  href="SURF_Reward-Deck.pdf"
                  target="_blank"
                  className="button-animated large w-inline-block"
                >
                  <div className="button-label">Pitch Deck</div>
                  <div className="button-hover-background" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <Box
            sx={{
              background: "#ffffff",
              backdropFilter: "blur(9px)",
              borderRadius: "20px",
              textAlign: "center",
              pb: 3,
            }}
          >
            <Box
              sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
              pb={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              bgcolor="rgb(37, 99, 235)"
            >
              <TimerCountDown time={1715030122} />
              <div className="meter">
                <span
                  style={{ width: `${+progressBar > 0 ? +progressBar : 0}%` }}
                ></span>
                <Box
                  sx={{
                    position: "absolute",
                    top: "10%",
                    left: "43%",
                    color: "#ffffff",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  {progressBar > 0
                    ? parseFloat(progressBar).toFixed(3)
                    : "0.00"}
                  %
                </Box>
              </div>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
              p={2}
            >
              <img width="35px" src={logo2} alt="" />
              <Divider sx={{ color: "rgb(37, 99, 235)", width: "50%" }}>
                1 SURF = $0.0325<br/>Listing = $0.05
              </Divider>
              <img
                width="40px"
                src={token === "ETH" ? eth : token === "BNB" ? bnb : usdt}
                alt=""
              />
            </Box>
            <Box display="flex" id="usdtraisedjs" alignItems="center" flexDirection="column">
              <StyledText>
                              USDT RAISED - ${raisedAmount ? raisedAmount : "loading..."}
                <span style={{ fontSize: "20px" }}>/</span>$1,100,000
              </StyledText>
              <p id="calculatingText"><center>Please reload if you cannot see raised Amount<br/>For ETH buyers: Switch to BNB/BSC to activate claiming button</center></p>
            </Box>
            <Box px={3}>
              {address && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box
                      textAlign="center"
                      mb={0.5}
                      fontWeight="500"
                      color="gray"
                      mt={2}
                      width="47%"
                    >
                      Your{" "}
                      {token === "ETH"
                        ? "ETH"
                        : token === "BNB"
                        ? "BNB"
                        : "USDT"}{" "}
                      Balance <br />{" "}
                      {token === "ETH"
                        ? balanceETH
                        : token === "BNB"
                        ? balanceBNB
                        : balanceUSDT}
                    </Box>
                    <Box
                      textAlign="center"
                      mb={0.5}
                      fontWeight="500"
                      color="gray"
                      mt={2}
                      width="47%"
                    >
                      Your SURF Balance <br /> {userPuchasedTokensToShow}
                    </Box>
                  </Box>
                  <Divider
                    style={{
                      background: "lightGray",
                      height: "1px",
                      marginBottom: "10px",
                    }}
                  />
                  <StyledText
                    onClick={() => {
                      connectedChainId === 1
                        ? switchNetwork?.(56)
                        : switchNetwork?.(1);
                    }}
                  >
                    <a
                      href=""
                      style={{ cursor: "pointer", color: "rgb(37, 99, 235)" }}
                    >
                      Switch network to buy with{" "}
                      {+connectedChainId === 1 ? "BNB" : "ETH"}
                    </a>
                  </StyledText>
                  <Box mt={1} />
                  {+connectedChainId === 1 || +web3ChainId === 1 ? (
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <StyledCurrencyButton
                          cursor="pointer"
                          color={
                            token === "ETH" ? "rgb(37, 99, 235)" : "#212529"
                          }
                          border={
                            token === "ETH"
                              ? "2px solid rgb(37, 99, 235)"
                              : "2px solid #dfdfdf"
                          }
                          onClick={() => setToken("ETH")}
                        >
                          ETH
                        </StyledCurrencyButton>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledCurrencyButton
                          cursor="pointer"
                          color={
                            token === "USDT" ? "rgb(37, 99, 235)" : "#212529"
                          }
                          border={
                            token === "USDT"
                              ? "2px solid rgb(37, 99, 235)"
                              : "2px solid #dfdfdf"
                          }
                          onClick={() => setToken("USDT")}
                        >
                          USDT
                        </StyledCurrencyButton>
                      </Grid>
                      {/* <Grid item xs={4}>
                        <StyledCurrencyButton
                          cursor="pointer"
                          color={
                            token === "CARD" ? "rgb(37, 99, 235)" : "#212529"
                          }
                          border={
                            token === "CARD"
                              ? "2px solid rgb(37, 99, 235)"
                              : "2px solid #dfdfdf"
                          }
                          onClick={() => setToken("CARD")}
                        >
                          CARD
                        </StyledCurrencyButton>
                      </Grid> */}
                    </Grid>
                  ) : (
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <StyledCurrencyButton
                          cursor="pointer"
                          color={
                            token === "BNB" ? "rgb(37, 99, 235)" : "#212529"
                          }
                          border={
                            token === "BNB"
                              ? "2px solid rgb(37, 99, 235)"
                              : "2px solid #dfdfdf"
                          }
                          onClick={() => {
                            setToken("BNB");
                          }}
                        >
                          BNB
                        </StyledCurrencyButton>
                      </Grid>
                      <Grid item xs={6}>
                        <StyledCurrencyButton
                          cursor="pointer"
                          color={
                            token === "USDT" ? "rgb(37, 99, 235)" : "#212529"
                          }
                          border={
                            token === "USDT"
                              ? "2px solid rgb(37, 99, 235)"
                              : "2px solid #dfdfdf"
                          }
                          onClick={() => {
                            setToken("USDT");
                          }}
                        >
                          USDT
                        </StyledCurrencyButton>
                      </Grid>
                      {/* <Grid item xs={4}>
                        <StyledCurrencyButton
                          cursor="pointer"
                          color={
                            token === "CARD" ? "rgb(37, 99, 235)" : "#212529"
                          }
                          border={
                            token === "CARD"
                              ? "2px solid rgb(37, 99, 235)"
                              : "2px solid #dfdfdf"
                          }
                          onClick={() => setToken("CARD")}
                        >
                          CARD
                        </StyledCurrencyButton>
                      </Grid> */}
                    </Grid>
                  )}
                  <Box
                    width="100%"
                    textAlign="left"
                    fontWeight={600}
                    color="#000000"
                    mt={2}
                  >
                    {token === "ETH" ? "ETH" : token === "BNB" ? "BNB" : "USDT"}{" "}
                    <span style={{ fontWeight: 400, color: "gray" }}>
                      you will pay
                    </span>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "#dfdfdf",
                        boxShadow: "3px 5px 5px 1px gray",
                      }}
                    >
                      <TextInput
                        fullWidth
                        placeholder="Enter amount"
                        type="number"
                        value={enteredAmount}
                        onChange={(e) => setEnteredAmount(e.target.value)}
                      />
                      <Box pr={1} display="flex" alignItems="center">
                        <img
                          src={
                            token === "ETH" ? eth : token === "BNB" ? bnb : usdt
                          }
                          alt=""
                          style={{
                            marginRight: "4px",
                            width: "30px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    width="100%"
                    textAlign="left"
                    fontWeight={600}
                    mt={matches ? 1 : 1}
                    color="#000000"
                  >
                    SURF{" "}
                    <span style={{ fontWeight: 400, color: "gray" }}>
                      you will get
                    </span>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        bgcolor: "#dfdfdf",
                        boxShadow: "3px 5px 5px 1px gray",
                      }}
                    >
                      <TextInput
                        fullWidth
                        placeholder="0"
                        type="number"
                        value={
                          +enteredAmount > 0
                            ? parseFloat(receivedTokens).toFixed(2)
                            : ""
                        }
                      />
                      <Box pr={1} display="flex" alignItems="center">
                        <img
                          src={logo2}
                          alt=""
                          style={{
                            marginRight: "4px",
                            width: "24px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={3} />
                  {address ? (
                    <StyledButton
                      colortext="#000000"
                      width="100%"
                      onClick={() => buyHadler()}
                    >
                      Buy Now
                    </StyledButton>
                  ) : (
                    <StyledButton
                      colortext="#000000"
                      width="100%"
                      onClick={async () => {
                        await open();
                      }}
                    >
                      Connect Wallet
                    </StyledButton>
                  )}
                  <Box mt={1} />
                </>
              )}
              {!address && <Box mt={2} />}
              <Button
                sx={{
                  color: "rgb(37, 99, 235)",
                  background: "transparent",
                  fontWeight: "500",
                  fontFamily: "Kanit",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  borderRadius: "50px",
                  width: "100%",
                  border: "2px solid rgb(37, 99, 235)",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                onClick={async () => {
                  await open();
                }}
              >
                {address
                  ? address.slice(0, 5) + "..." + address.slice(-5)
                  : "Connect Wallet"}
              </Button>

              <Box
                mt={2}
                sx={{
                  pl: 1,
                  bgcolor: "#dfdfdf",
                  boxShadow: "3px 5px 5px 1px gray",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "48px",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 300,
                    fontSize: "15px",
                    color: "#000000",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: "Kanit",
                  }}
                >
                  {address
                    ? window.location.origin + "/?ref=" + address
                    : "Connect wallet and get referral link"}
                </Typography>
                {address ? (
                  <CopyToClipboard
                    text={window.location.origin + "/?ref=" + address}
                    onCopy={() => {
                      setAlertState({
                        open: true,
                        message: `Refferal Link Copied.`,
                        severity: "success",
                      });
                    }}
                  >
                    <Box
                      sx={{
                        background: "rgb(37, 99, 235)",
                        borderRadius: "9px",
                        fontSize: "12px",
                        color: "#ffffff",
                        p: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <ContentCopyIcon />
                    </Box>
                  </CopyToClipboard>
                ) : (
                  <Box
                    sx={{
                      background: "rgb(37, 99, 235)",
                      borderRadius: "9px",
                      fontSize: "12px",
                      color: "#ffffff",
                      p: "10px",
                      cursor: "no-drop",
                    }}
                  >
                    <ContentCopyIcon />
                  </Box>
                )}
              </Box>

              {admin.toLowerCase() === address?.toLowerCase() && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  mt={1}
                >
                  <Box
                    display="flex"
                    alignItems="center "
                    justifyContent="center"
                    width={matches ? "100%" : "49%"}
                  >
                    <CSVLink
                      style={{
                        color: "#000000",
                        background: "rgb(37, 99, 235)",
                        fontSize: "18px",
                        textTransform: "capitalize",
                        borderRadius: "50px",
                        padding: "10px 20px",
                        textDecoration: "none",
                        width: "100%",
                      }}
                      filename={"Addresses.csv"}
                      data={csvAddresses}
                    >
                      Addresses CSV
                    </CSVLink>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center "
                    justifyContent="center"
                    width={matches ? "100%" : "49%"}
                    mt={matches ? 2 : 0}
                  >
                    <CSVLink
                      style={{
                        color: "#000000",
                        background: "rgb(37, 99, 235)",
                        fontSize: "18px",
                        textTransform: "capitalize",
                        borderRadius: "50px",
                        padding: "10px 20px",
                        textDecoration: "none",
                        width: "100%",
                      }}
                      data={csvAmounts}
                      filename={"Amounts.csv"}
                    >
                      Amounts CSV
                    </CSVLink>
                  </Box>
                </Box>
              )}
            {(+userPurchasedTokens > 0 || +userPurchasedTokensETH > 0) && 
              +connectedChainId === 56 ? ( 
                <Box mt={1}> 
                  <StyledButton 
                    onClick={() => claimHandler()} 
                    width="100%" 
                    colortext="#ffffff" 
                  > 
                    Claim SURF Tokens 
                  </StyledButton> 
 
                  <StyledText> 
                    You can claim and stake tokens after buying 
                  </StyledText> 
                </Box> 
              ) : null}
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
