import React from 'react'

function Footer() {
    return (
        <section id="contact-us">
            <div className="footer-1-dark-with-cta">
                <div className="container-lg">
                    <div className="w-layout-grid footer-grid">
                        <div
                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdbd-2fe08bdf"
                            className="w-layout-grid footer-1-main-grid"
                        >
                            <a
                                id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdbe-2fe08bdf"
                                href="index-2.html"
                                aria-current="page"
                                className="footer-1-logo w-inline-block w--current"
                            >
                                <img
                                    src="images/Logo2_transparent_backgroundBG_.png"
                                    loading="lazy"
                                    alt=""
                                    className="image-contain"
                                />
                            </a>
                            <div id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc0-2fe08bdf">
                                <div className="w-layout-grid footer-1-sub-grid">
                                    <div className="w-layout-grid column-sm">
                                        <div
                                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc3-2fe08bdf"
                                            className="margin-bottom margin-3"
                                        >
                                            <h6 className="footer-1-nav-heading">Links</h6>
                                        </div>
                                        <a
                                            href="#about"
                                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc6-2fe08bdf"
                                            className="footer-1-link-gray"
                                        >
                                            What is SURF?
                                        </a>
                                        <a
                                            href="#how-it-works"
                                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdc8-2fe08bdf"
                                            className="footer-1-link-gray"
                                        >
                                            How it works
                                        </a>
                                        <a
                                            href="#specials"
                                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdca-2fe08bdf"
                                            className="footer-1-link-gray"
                                        >
                                            Bonus Specials
                                        </a>
                                        <a
                                            href="#roadmap"
                                            id="w-node-_9631021f-df77-3513-f1b4-c56499c56d5e-2fe08bdf"
                                            className="footer-1-link-gray"
                                        >
                                            Roadmap
                                        </a>
                                        <a
                                            href="#team"
                                            id="w-node-c3a61833-745a-042d-c852-af21956fbdf7-2fe08bdf"
                                            className="footer-1-link-gray"
                                        >
                                            Team
                                        </a>
                                        <a
                                            id="w-node-e1859f66-c0ca-12ea-502c-dc7bdc7b87be-2fe08bdf"
                                            href="jobs.html"
                                            className="footer-1-link-gray"
                                        >
                                            Jobs
                                        </a>
                                        <a
                                            href="#contact-us"
                                            id="w-node-b05a9ee4-e52b-26a7-5019-06bdd231fea4-2fe08bdf"
                                            className="footer-1-link-gray"
                                        >
                                            Contact
                                        </a>
                                    </div>
                                    <div className="w-layout-grid column-sm">
                                        <div
                                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdcd-2fe08bdf"
                                            className="margin-bottom margin-3"
                                        >
                                            <h6 className="footer-1-nav-heading">Contact </h6>
                                        </div>
                                        <style
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    "\n                      .noHover {\n                        pointer-events: none;\n                      }\n                    "
                                            }}
                                        />
                                        <a
                                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdd0-2fe08bdf"
                                            href="#"
                                            className="footer-1-link-gray noHover"
                                        >
                                            SURF Reward
                                            <br />
                                            <br />A project of:
                                            <br />
                                            Abelius Capital AG
                                            <br />
                                            Dreischeibenhaus 1<br />
                                            D-40211 Düsseldorf
                                            <br />
                                            info@surfreward.io
                                        </a>
                                        <div
                                            id="w-node-_33bc4dba-1c05-904a-a102-22f66610a568-2fe08bdf"
                                            className="div-block"
                                        >
                                            <a
                                                href="https://twitter.com/surf_reward"
                                                target="_blank"
                                                className="w-inline-block"
                                            >
                                                <img
                                                    src="images/Pfad-21.svg"
                                                    loading="lazy"
                                                    alt=""
                                                    className="image-11"
                                                />
                                            </a>
                                            <a
                                                href="mailto:info@surfreward.io"
                                                className="w-inline-block"
                                            >
                                                <img
                                                    src="images/Pfad-24.svg"
                                                    loading="lazy"
                                                    alt=""
                                                    className="image-11"
                                                />
                                            </a>
                                            <a
                                                href="https://t.me/surfreward_community"
                                                target="_blank"
                                                className="w-inline-block"
                                            >
                                                <img
                                                    src="images/Pfad-25.svg"
                                                    loading="lazy"
                                                    alt=""
                                                    className="image-11"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div id="w-node-be93de02-a0ed-0911-1ddb-ed7e0f882155-2fe08bdf">
                                        <div>
                                            <img src="images/dreischeibenhaus.jpg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="w-node-_598d85a8-9abe-685f-3adb-b1565ef83e7b-2fe08bdf"
                                className="div-block-2"
                            />
                        </div>
                        <div
                            id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdfd-2fe08bdf"
                            className="footer-1-meta"
                        >
                            <div className="w-layout-grid row-base column-mobile">
                                <a
                                    href="https://surfreward.io/x/amlcft.html"
                                    id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdff-2fe08bdf"
                                    className="footer-1-link-gray"
                                >
                                    AML &amp; CFT Policy
                                </a>
                                <a
                                    href="https://surfreward.io/x/privacypolicy.html"
                                    id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdff-2fe08bdf"
                                    className="footer-1-link-gray"
                                >
                                    Privacy Policy
                                </a>
                                {/* <a href="terms.html" id="w-node-a7e12a0c-d703-16d6-f053-4efa14dbbdff-2fe08bdf" class="footer-1-link-gray">Terms and Conditions</a> */}
                            </div>
                        </div>
                        <div className="footer-1-link-gray">
                            © 2019-2023 by SURF Reward. A Project of Abelius Capital AG - All
                            rights reserved
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Footer